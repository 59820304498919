// LandingPage.js
import React, { Suspense, lazy, useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Masonry from "react-masonry-css";
import "./LandingPage.css";
import axios from "axios";
import FadeIn from "../../animations/fadeIn/FadeIn";

import Logo from "../../assets/logo.png";

// Lazy loading komponentów
const AnimatedPage = lazy(() => import("../AnimatedPage/AnimatedPage"));
const Gallery = lazy(() => import("../Gallery/Gallery"));
const Offer = lazy(() => import("../Offer/Offer"));
const Quote = lazy(() => import("../Quote/Quote"));
const About = lazy(() => import("../About/About"));
const Banner = lazy(() => import("../Banner/Banner"));
const Footer = lazy(() => import("../Footer/Footer"));

// Komponent pojedynczego zdjęcia z animacją
const MasonryImage = ({ photo, index }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div
      className={`masonry-item ${photo.size} ${isLoaded ? "visible" : ""}`}
      style={{
        transitionDelay: `${index * 150}ms`, // Opóźnienie narastające dla każdego kolejnego zdjęcia
      }}
    >
      <img
        src={`${photo.url}`}
        alt={`Portfolio ${photo.id}`}
        loading="lazy"
        onLoad={() => setIsLoaded(true)}
        className={isLoaded ? "loaded" : ""}
      />
    </div>
  );
};

const LandingPage = () => {
  const [photos, setPhotos] = useState([]);
  const [data, setData] = useState([]);

  const breakpointColumns = {
    default: 4,
    1400: 3,
    992: 2,
    576: 1,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [photosRes, dataRes] = await Promise.all([
          axios.get("/api/getColl"),
          axios.get("/api/landing-page"),
        ]);

        setPhotos(photosRes.data);
        setData(dataRes.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);

  return (
    <Suspense fallback={<div />}>
      <AnimatedPage>
        <Container fluid className="landing-container" id="phone-view-landing">
          <div className="phone-view-box">
            <img
              src={Logo}
              alt="Logo"
              style={{
                width: "95%",
                marginTop: "-10rem",
                marginRight: "0%",
                marginLeft: "0%",
                marginBottom: "10%",
              }}
            />
            {/* <h1 className="display-4 mb-4">{data[0]?.title}</h1> */}
            <p className="lead mb-4">{data[0]?.description}</p>
            <div className="buttons-wrapper">
              <Button
                variant="outline-light"
                size="lg"
                className="banner-button"
                onClick={() => (window.location.href = '#')}
              >
                Kontakt
              </Button>
            </div>
          </div>
          <Row className="h-100">
            <Col
              md={6}
              className="d-flex flex-column justify-content-center text-column d-none d-sm-none d-md-flex d-lg-flex"
            >
              <img
                src={Logo}
                alt="Logo"
                style={{ margin: "0 auto", width: "80%" }}
              />
              {/* <h1 className="display-4 mb-4">{data[0]?.title}</h1> */}
              <p
                className="lead mb-4"
                style={{
                  marginTop: "2rem",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                }}
              >
                {data[0]?.description}
              </p>
              <div className="buttons-wrapper">
                <Button
                  variant="outline-light"
                  size="lg"
                  className="banner-button"
                  onClick={() => (window.location.href = "#")}
                >
                  Kontakt
                </Button>
              </div>
            </Col>

            <Col md={6} className="photo-column">
              <div className="photo-grid-container">
                <Masonry
                  breakpointCols={breakpointColumns}
                  className="masonry-grid"
                  columnClassName="masonry-grid_column"
                >
                  {photos.map((photo, index) => (
                    <MasonryImage key={photo.id} photo={photo} index={index} />
                  ))}
                </Masonry>
              </div>
            </Col>
          </Row>
        </Container>
        <About />
      </AnimatedPage>

      <AnimatedPage>
        <Gallery />
        <Quote />
        <Offer />
        <Banner />
        <Footer />
      </AnimatedPage>
    </Suspense>
  );
};

export default LandingPage;
